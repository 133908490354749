import { template as template_234c9cf35a2140e99c95d8538ba64b29 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_234c9cf35a2140e99c95d8538ba64b29(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
