import { template as template_5a97e80899e1408485c31dbe38cd4f74 } from "@ember/template-compiler";
const FKText = template_5a97e80899e1408485c31dbe38cd4f74(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
