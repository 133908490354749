import { template as template_754b9ecd65fb472abd39d51f2e63f49f } from "@ember/template-compiler";
const FKControlMenuContainer = template_754b9ecd65fb472abd39d51f2e63f49f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
