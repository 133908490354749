import { template as template_e73ae75f73df492389fdde01e14d2238 } from "@ember/template-compiler";
const FKLabel = template_e73ae75f73df492389fdde01e14d2238(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
